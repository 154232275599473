import React from 'react'

import {
  LazyLoadComponent,
  //   LazyLoadImage,
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

//swiper
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

//images
import GroupBg1 from '../../images/new-homepage/group-bg-1.jpg'
import GroupBg2 from '../../images/new-homepage/group-bg-2.jpeg'
import GroupBg3 from '../../images/new-homepage/group-bg-3.jpeg'
import GroupBg4 from '../../images/new-homepage/group-bg-4.jpg'
import GroupBg5 from '../../images/new-homepage/group-bg-5.jpg'

import UnityPointHealthLogo from '../../images/new-homepage/unitypoint-health.png'
import SclHealthLogo from '../../images/new-homepage/scl-health.png'
import BannerHealth from '../../images/new-homepage/Banner_Health_logo.png'

// icons
// import LeftArrowIcon from '../../images/new-homepage/left-arrow.svg'
// import RightArrowIcon from '../../images/new-homepage/right-arrow.svg'

const RequestSwiper = () => {
  const GroupSwiperData = [
    {
      quote: `"Sandy’s presence immediately disarms even the most doubtful physician and allows for
      rapid, meaningful growth. I would recommend her physician coaching program without
      hesitation."`,
      // role: 'OB/GYN (Denver, Colorado)',
      img: GroupBg1,
      logo: UnityPointHealthLogo,
    },
    {
      quote: `"I have worked with Sandy in two different health systems. She connects with physicians
      and breaks through their guarded skepticism to unlock their true passion for patient care,
      empathy, and leadership. The result is a resilient physician leader that can help to move
      the culture of the organization."`,
      // role: 'Medical Director (New England)',
      img: GroupBg2,
      logo: SclHealthLogo,
    },
    {
      quote: `"During my coaching… I set clearer expectations and guided my team to come up with a
      solution which they implemented. Staffing, morale, and patient care have all improved. I
      learned I don’t need to fix things by myself."`,
      role: 'Medical Director (New England)',
      img: GroupBg3,
    },
    {
      quote: `"Many healthcare leaders, especially physicians, are skeptical of the value of coaching.
      Sandy cured my skepticism in just a few brief conversations. I can say without reservation
      that she is the best coach I’ve encountered. She was the driving force behind a highly
      successful program, where she helped transform physician leadership training"`,
      img: GroupBg4,
      logo: BannerHealth,
    },
    {
      quote: `"Sandy gets the physician perspective and helps us all realize our potential as leaders"`,
      role: 'Chief Medical Officer (Scottsdale, Arizona)',
      img: GroupBg5,
    },
  ]

  const articleCarousel = {
    loop: true,
    // spaceBetween: 10,
    speed: 2000,
    autoplay: {
      delay: 10000,
    },
    // autoplay: false,
    disableOnInteraction: false,
    navigation: {
      nextEl: '#article-next',
      prevEl: '#article-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      767: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  }

  return (
    <>
      <div className="request-swiper" style={{ width: '100%', height: '100%' }}>
        {/* <div className="container h-100 w-100"> */}
        {/* <div className="row h-100 w-100"> */}
        {/* <div class="fullwidth-nav d-none">
          <div class="fullwidth-nav-prev" id="article-prev">
            <img src={LeftArrowIcon} alt="Left Arrow" />
          </div>
          <div class="fullwidth-nav-next" id="article-next">
            <img src={RightArrowIcon} alt="Right Arrow" />
          </div>
        </div> */}
        <LazyLoadComponent>
          <Swiper {...articleCarousel}>
            {GroupSwiperData.map(group => (
              <div className="swiper-slide" style={{ position: 'relative' }}>
                <img
                  className="group-swiper-img"
                  src={group.img}
                  alt="Groups and Partners"
                  style={{
                    height: '100vh',
                    objectFit: 'cover',
                    width: '100%',
                    zIndex: -1,
                    filter: 'opacity(0.3)',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10%',
                    left: 0,
                    // bottom: 0,
                    zIndex: 1,
                    padding: '0 5rem',
                    width: '85%',
                  }}
                >
                  <p className="text-dark review-quote">{group.quote}</p>
                  {(group.role && (
                    <p className="text-white review-role mt-4 text-dark">
                      {group.role}
                    </p>
                  )) ||
                    null}
                  {(group.logo && (
                    <div
                      className="mt-5"
                      style={{
                        padding: '1rem',
                        width: '50%',
                      }}
                    >
                      <img
                        src={group.logo}
                        alt="company logo"
                        // style={{ width: '50%' }}
                      />
                    </div>
                  )) ||
                    null}
                </div>
              </div>
            ))}
          </Swiper>
        </LazyLoadComponent>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default RequestSwiper
