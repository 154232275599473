import React from 'react'
// import Header from '../components/header/header'
import SEO from '../components/seo'
import { Link } from 'gatsby'

// import { LazyLoadImage } from 'react-lazy-load-image-component'
//form
// import RequestForm from '../components/forms/request-form'
// import ResourcesForm from '../components/forms/resources-form'
import GroupProgramsForm from '../components/forms/group-programs-form'

//swiper
// import RequestSwiper from '../components/swiper/request-swiper'
import GroupSwiper from '../components/swiper/group-swiper'

//images
import logo from '../images/logo.png'

const GroupPrograms = () => {
  return (
    <>
      {/* <Header /> */}
      <SEO
        title="Let’s accelerate your physician leaders"
        description="We’re here to help you develop your physician leadership pipeline and bridge any dyad gaps, because
it's our best shot at making healthcare work better for patients. And we bring together the best strategies, tools
and experience to help you do just that."
      />
      <div className="rfcs-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4 col-md-10 mx-auto"
              style={{ marginTop: '7.5rem' }}
            >
              <Link to="/">
                <img
                  // width={scrollY > 0 ? "100%" : "100%"}
                  id="logo-size"
                  src={logo}
                  alt="Sandy Scott"
                  className="logo sepia img-fluid"
                  style={{ width: '40%' }}
                />
              </Link>
              <div
                className="mt-5"
                // style={{
                //   height: '90vh',
                //   display: 'flex',
                //   flexDirection: 'column',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   padding: '8rem 0',
                // }}
              >
                <h1 className="mb-0">
                  Let’s accelerate your physician leaders
                </h1>
                <h4 className="my-4">
                  We’re here to help you develop your physician leadership
                  pipeline and bridge any dyad gaps, because it's our best shot
                  at making healthcare work better for patients. And we bring
                  together the best strategies, tools and experience to help you
                  do just that.
                </h4>
                <p>
                  Tell us a bit about you, so we can send you helpful
                  information about how we’ve partnered with organizations like
                  yours.
                </p>
                <div className="text-left w-100 mt-4">
                  <Link
                    to="/groups/#group-form-wrapper"
                    className="btn-default btn-purple"
                  >
                    Request More Information
                  </Link>
                </div>
              </div>
              <div
                id="group-form-wrapper"
                style={{
                  height: '90vh',
                  marginTop: '10rem',
                }}
              >
                <GroupProgramsForm />
              </div>
            </div>
            <div className="col-lg-6 px-0">
              <div
                // className="test"
                style={{
                  height: '100vh',
                  position: 'fixed',
                }}
              >
                <GroupSwiper />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GroupPrograms
